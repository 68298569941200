import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { APP_TITLE } from '~/config';
import { Helmet } from "react-helmet";
import { getDefaultConfig, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import { base } from 'wagmi/chains';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Layout from './Layout';
import ClubsList from './Clubs/List';
import ClubsView from './Clubs/View';
import TradesList from './Trades/List';
import TransfersList from './Transfers/List';


export const WAGMI_CONFIG = getDefaultConfig({
    appName: 'clubs.frenfren.pro',
    projectId: '4e45527b2ae5173f9c9d03605ace77b7',
    chains: [base],
});

const queryClient = new QueryClient()

export default function App() {
    return (
        <Providers>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{APP_TITLE}</title>
            </Helmet>

            <Layout>
                <Routes>
                    <Route path="/clubs" element={<ClubsList />} />
                    <Route path="/clubs/:id" element={<ClubsView />} />
                    <Route path="/trades" element={<TradesList />} />
                    <Route path="/trades/:id" element={<TradesList />} />
                    <Route path="/transfers/:id" element={<TransfersList />} />
                    <Route path="/clubs/owner/:ownerAddress" element={<ClubsList />} />
                    <Route path="*" element={<ClubsList />} />
                </Routes>
            </Layout>
        </Providers>
    )
}

function Providers({ children }: { children: React.ReactNode }) {
    return (
        <WagmiProvider config={WAGMI_CONFIG}>
            <QueryClientProvider client={queryClient}>
                <RainbowKitProvider>
                    <Router>
                        {children}
                    </Router>
                </RainbowKitProvider>
            </QueryClientProvider>
        </WagmiProvider>
    );
}