import { useQuery } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import { GQL_ENDPOINT } from "~/config"

import type { ClubToken, TokenTransfer } from "~/../.graphclient"
interface ClubsQuery {
  clubTokens: (ClubToken & { createdWith: [TokenTransfer] })[]
}

const ClubsQueryDocument = gql`
query TopClubSearch(
  $ownerAddress: String = "",
  $name_contains_nocase: String = "",
  $createdBy_contains_nocase: String = "",
  $first: Int = 10,
  $skip: Int = 0,
  $orderBy: ClubToken_orderBy = "holdersCount",
  $orderDirection: OrderDirection = "desc"
) {
  clubTokens(
    orderBy: $orderBy
    orderDirection: $orderDirection
    first: $first
    skip: $skip
    where: {
      name_contains_nocase: $name_contains_nocase,
      createdBy_contains_nocase: $createdBy_contains_nocase,
      holdersCount_not: 0,
      holders_: { account: $ownerAddress, amount_not: 0 }
    }
  ) {
    id
    name
    createdAt
    createdBy {
      id
    }
    updatedAt
    holdersCount
    tradesCount
    transfersCount
    priceBuyGross
    priceSellGross
    volume
    volumeBuy
    volumeSell
    createdWith: transfers(first: 1, orderBy: timestamp, orderDirection: asc) {
      transaction {
        id
      }
    }
  }
}
`

const ClubsQueryDocumentById = gql`
query TopClubSearch(
  $ownerAddress: String = "",
  $identifier: BigInt = "",
  $createdBy_contains_nocase: String = "",
  $first: Int = 10,
  $skip: Int = 0,
  $orderBy: ClubToken_orderBy = "holdersCount",
  $orderDirection: OrderDirection = "desc"
) {
  clubTokens(
    orderBy: $orderBy
    orderDirection: $orderDirection
    first: $first
    skip: $skip
    where: {
      identifier: $identifier,
      createdBy_contains_nocase: $createdBy_contains_nocase,
      holdersCount_not: 0,
      holders_: { account: $ownerAddress, amount_not: 0 }
    }
  ) {
    id
    name
    createdAt
    createdBy {
      id
    }
    updatedAt
    holdersCount
    tradesCount
    transfersCount
    priceBuyGross
    priceSellGross
    volume
    volumeBuy
    volumeSell
    createdWith: transfers(first: 1, orderBy: timestamp, orderDirection: asc) {
      transaction {
        id
      }
    }
  }
}
`

type OptionsParams = {
  first?: number
  skip?: number
  orderBy?: string
  orderDirection?: string
  filters?: Record<string, unknown>
}
export function useClubsSearch({ filters, ...options }: OptionsParams) {

  return useQuery({
    queryKey: ['clubs', JSON.stringify(options), JSON.stringify(filters)],
    queryFn: async () => {
      const { clubTokens } = await request<ClubsQuery & { createdWith: [TokenTransfer] }>(
        GQL_ENDPOINT,
        filters?.identifier ? ClubsQueryDocumentById : ClubsQueryDocument,
        {
          ...{ first: 2, skip: 0, ...options },
          ...filters
        }
      )
      return clubTokens
    },
    placeholderData: (previousData) => previousData,
    retry: false
  })
}