import { useQuery } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import { GQL_ENDPOINT } from "~/config"

import type { TokenTrade } from "~/../.graphclient"
interface TradesQuery {
    tokenTrades: TokenTrade[]
}

const TradesQueryDocument = gql`
query TradesSearch($trader_contains_nocase: String = "", $token_contains_nocase: String = "", $first: Int = 10, $skip: Int = 0, $orderBy: TokenTrade_orderBy = "timestamp", $orderDirection: OrderDirection = "desc") {
    tokenTrades(
        orderBy: $orderBy
        orderDirection: $orderDirection
        first: $first
        skip: $skip
        where: {trader_contains_nocase: $trader_contains_nocase, token_: { name_contains_nocase: $token_contains_nocase } }
    ) {
        amount
        friendValueExact
        friendValue
        isBuy
        timestamp
        transaction {
            id
            timestamp
            blockNumber
        }
        id
        traderHolder {
            amount
            account {
                id
                ClubTokenholders(first: 25) {
                    token {
                        name
                    }
                }
            }
        }
        token {
            id
            identifier
            name
            priceBuy
            priceBuyExact
            priceBuyGross
            priceBuyGrossExact
            priceSell
            priceSellExact
            priceSellGross
            priceSellGrossExact
            tradesCount
            transfersCount
            updatedAt
            createdAt
            createdBy {
                id
            }
            holdersCount
            holdersCountPast
        }
    }
}
`

const TradesQueryDocumentById = gql`
query TradesSearch($token: String = "", $trader_contains_nocase: String = "", $first: Int = 10, $skip: Int = 0, $orderBy: TokenTrade_orderBy = "timestamp", $orderDirection: OrderDirection = "desc") {
    tokenTrades(
        orderBy: $orderBy
        orderDirection: $orderDirection
        first: $first
        skip: $skip
        where: { trader_contains_nocase: $trader_contains_nocase, token: $token }
    ) {
        amount
        friendValueExact
        friendValue
        isBuy
        timestamp
        transaction {
            id
            timestamp
            blockNumber
        }
        id
        traderHolder {
            amount
            account {
                id
                ClubTokenholders(first: 25) {
                    token {
                        name
                    }
                }
            }
        }
        token {
            id
            identifier
            name
            priceBuy
            priceBuyExact
            priceBuyGross
            priceBuyGrossExact
            priceSell
            priceSellExact
            priceSellGross
            priceSellGrossExact
            tradesCount
            transfersCount
            updatedAt
            createdAt
            createdBy {
                id
            }
            holdersCount
            holdersCountPast
        }
    }
}
`
type OptionsParams = {
    first?: number
    skip?: number
    orderBy?: string
    orderDirection?: string
    filters?: Record<string, unknown>
}
export function useTradesSearch({ filters, ...options }: OptionsParams) {

    return useQuery({
        queryKey: ['trades', JSON.stringify(options), JSON.stringify(filters)],
        queryFn: async () => {
            const { tokenTrades } = await request<TradesQuery>(
                GQL_ENDPOINT,
                filters?.token ? TradesQueryDocumentById : TradesQueryDocument,
                {
                    ...{ first: 2, skip: 0, ...options },
                    ...filters
                }
            )
            return tokenTrades
        },
        placeholderData: (previousData) => previousData,
        retry: false
    })
}