import type { TokenTrade } from "~/../.graphclient"
import { formatDistanceToNow } from "date-fns";
import { PlusCircleIcon as IconBuy, MinusCircleIcon as IconSell, Bars2Icon as IconSum } from '@heroicons/react/20/solid'


function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

import Fren from "~/common/Fren";
export default function Trades({ data, limit, showSum = false }: { data: TokenTrade[], limit?: number, showSum?: boolean }) {
    const trades = limit ? data.slice(0, limit) : data
    const sum = trades.reduce((acc, trade) => trade.isBuy ? acc - parseFloat(trade.friendValue) : acc + parseFloat(trade.friendValue), 0);
    return (
        <>
            <ul role="list" className={`space-y-6 relative ${!limit && trades.length > 3 ? 'overflow-y-scroll max-h-48' : ''}`}>
                {trades.map((trade, activityItemIdx) => (
                    <li key={trade.id} className="relative flex gap-x-4">
                        <div
                            className={classNames(
                                !showSum && activityItemIdx === trades.length - 1 ? 'h-6' : '-bottom-6',
                                'absolute left-0 top-0 flex w-6 justify-center'
                            )}
                        >
                            <div className="w-px bg-gray-200" />
                        </div>
                        <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                            {trade.isBuy ? (
                                <IconBuy className="h-6 w-6 text-green-500" aria-hidden="true" />
                            ) : (
                                <IconSell className="h-6 w-6 text-red-500" aria-hidden="true" />
                            )}
                        </div>
                        <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500 space-x-1">
                            <span className="font-medium text-gray-900">
                                <Fren address={trade.trader?.id} short link />
                            </span>
                            <span>
                                {trade.isBuy ? 'joined' : 'left'} {trade.amount > 1 ? `with ${trade.amount} Keys` : ''}
                            </span>
                        </p>
                        <time dateTime={trade.timestamp} className="flex-none text-right py-0.5 text-xs leading-5 text-gray-500">
                            <div>{formatDistanceToNow(new Date(trade.timestamp * 1000), { addSuffix: true })}</div>
                            <div className={`font-mono ${trade.isBuy ? 'text-red-500' : 'text-green-500'}`}>{trade.isBuy ? '-' : '+'}{trade.friendValue} $FRIEND</div>
                        </time>
                    </li>
                ))}
            </ul>
            <ul role="list" className="space-y-6" >
                {showSum && (
                    <li className="relative flex gap-x-4">
                        <div
                            className={classNames(
                                'h-6',
                                'absolute left-0 top-0 flex w-6 justify-center'
                            )}
                        >
                            <div className="w-px bg-gray-200" />
                        </div>
                        <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                            <IconSum className="h-6 w-6 text-gray-500" aria-hidden="true" />
                        </div>
                        <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500 space-x-1">
                            <span className="font-medium text-gray-900">
                                {trades.length} {trades.length === 1 ? 'Trade' : 'Trades'}
                            </span>
                            <span>
                            </span>
                        </p>
                        <span className="flex-none text-right py-0.5 text-xs leading-5 text-gray-500">
                            <div className={`font-mono ${sum < 0 ? 'text-red-500' : 'text-green-500'}`}>{sum < 0 ? '-' : '+'}{Math.abs(sum).toFixed(4)} $FRIEND</div>
                        </span>
                    </li>
                )}
            </ul>
        </>
    )
}
