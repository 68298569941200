import { useQuery } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import { GQL_ENDPOINT } from "~/config"

import type { TokenTransfer } from "~/../.graphclient"
interface TransferQuery {
    tokenTransfers: TokenTransfer[]
}

const TransfersQueryDocument = gql`
    query TransfersSearch($name_contains_nocase: String = "", $from_contains_nocase: String = "", $to_contains_nocase: String = "", $first: Int = 10, $skip: Int = 0, $orderBy: TokenTransfer_orderBy = "timestamp", $orderDirection: OrderDirection = "desc") {
        tokenTransfers(
            orderBy: $orderBy
            orderDirection: $orderDirection
            first: $first
            skip: $skip
            where: {
                token_: {name_contains_nocase: $name_contains_nocase },
                from_contains_nocase: $from_contains_nocase,
                to_contains_nocase: $to_contains_nocase,
                from_not: null,
                to_not: null
            }
        ) {
            timestamp
            id
            from {
                id
            }
            to {
                id
            }
            token {
                id
                identifier
                name
            }
            toBalance {
                amount
            }
            fromBalance {
                amount
            }
            amount
            transaction {
                id
                timestamp
            }
        }
    }
`

const TransfersQueryDocumentById = gql`
    query TransfersSearch($token: String = "", $from_contains_nocase: String = "", $to_contains_nocase: String = "", $first: Int = 10, $skip: Int = 0, $orderBy: TokenTransfer_orderBy = "timestamp", $orderDirection: OrderDirection = "desc") {
        tokenTransfers(
            orderBy: $orderBy
            orderDirection: $orderDirection
            first: $first
            skip: $skip
            where: {
                token: $token,
                from_contains_nocase: $from_contains_nocase,
                to_contains_nocase: $to_contains_nocase,
                from_not: null,
                to_not: null
            }
        ) {
            timestamp
            id
            from {
                id
            }
            to {
                id
            }
            token {
                id
                identifier
                name
            }
            toBalance {
                amount
            }
            fromBalance {
                amount
            }
            amount
            transaction {
                id
                timestamp
            }
        }
    }
`
type OptionsParams = {
    first?: number
    skip?: number
    orderBy?: string
    orderDirection?: string
    filters?: Record<string, unknown>
}
export function useTransfersSearch({ filters, ...options }: OptionsParams) {
    return useQuery({
        queryKey: ['transfers', JSON.stringify(options), JSON.stringify(filters)],
        queryFn: async () => {
            const { tokenTransfers } = await request<TransferQuery>(
                GQL_ENDPOINT,
                filters?.token ? TransfersQueryDocumentById : TransfersQueryDocument,
                {
                    ...{ first: 2, skip: 0, ...options },
                    ...filters
                }
            )
            return tokenTransfers
        },
        placeholderData: (previousData) => previousData,
        retry: false
    })
}