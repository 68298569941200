import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useClub } from '~/hooks/useClub'
import { useAccount } from 'wagmi'
import { BaseError } from 'viem';
import { CLUBS } from '~/config/Contracts'
import { WAGMI_CONFIG } from '~/App';
import { useReadContract, useWriteContract, useWaitForTransactionReceipt } from 'wagmi'
import { simulateContract } from '@wagmi/core'

type Props = {
    ids: string[]
    isBuy: boolean
    onClose: () => void
}
export default function Batch({ ids, isBuy, onClose }: Props) {
    const [currentClubId, setCurrentClubId] = useState('')
    const [handledClubIds, setHandledClubIds] = useState<string[]>([])
    const [errorMessage, setErrorMessage] = useState('')
    const club = useClub(currentClubId)
    const account = useAccount()
    const { writeContract, data: txId, reset: resetTx } = useWriteContract()
    const receipt = useWaitForTransactionReceipt({ hash: txId })

    const totalSupply = useReadContract({
        ...CLUBS,
        functionName: 'totalSupply',
        args: [BigInt(club.data?.id ?? 0)]
    })

    const balance = useReadContract({
        ...CLUBS,
        functionName: 'balanceOf',
        args: [(account.address ?? '0x0000000000000000000000000000000000000000'), BigInt(club.data?.id ?? 0)]
    })

    const currentBuyPrice = useReadContract({
        ...CLUBS,
        functionName: 'getPriceOut',
        args: [(totalSupply.data ?? 0n), BigInt(1), club.data?.coefficient]
    })

    const currentSellPrice = useReadContract({
        ...CLUBS,
        functionName: 'getPriceOut',
        args: [(totalSupply.data ?? 0n) - (balance.data ?? 0n), (balance.data ?? 0n), club.data?.coefficient]
    })

    const protocolFeePercent = useReadContract({
        ...CLUBS,
        functionName: 'protocolFeePercent'
    })

    const bestFriendFeePercent = useReadContract({
        ...CLUBS,
        functionName: 'bestFriendFeePercent'
    })

    useEffect(() => {
        setCurrentClubId(ids[0])
    }, [ids])

    const cancelButtonRef = useRef(null)

    const skipClub = () => {
        if (!currentClubId) { return }
        setHandledClubIds(ids => [...ids, currentClubId])
        setCurrentClubId(ids[handledClubIds.length + 1] ?? '')
    }

    const handleBuy = async () => {
        if (!currentBuyPrice.data || !bestFriendFeePercent.data || !protocolFeePercent.data || !club.data) { return }
        const bestFriendFee = currentBuyPrice.data * bestFriendFeePercent.data / BigInt(1e18);
        const protocolFee = currentBuyPrice.data * protocolFeePercent.data / BigInt(1e18);
        const tokensIn = currentBuyPrice.data + bestFriendFee + protocolFee

        try {
            setErrorMessage('')
            await simulateContract(WAGMI_CONFIG, {
                ...CLUBS,
                functionName: 'buyToken',
                args: [BigInt(club.data.id ?? 0), tokensIn, 1n, '0x27DFcf2F8E8e07Adafcfcf0f81299B909E91Ee32']
            })

            writeContract({
                ...CLUBS,
                functionName: 'buyToken',
                args: [BigInt(club.data.id ?? 0), tokensIn, 1n, '0x27DFcf2F8E8e07Adafcfcf0f81299B909E91Ee32']
            })
        }
        catch (err: any) {
            const errorMessage =
                err instanceof (BaseError) ? err.shortMessage :
                    err instanceof (Error) ? err.message : String(err)
            setErrorMessage(errorMessage)
            console.error(err)
        }
    }

    const handleSell = async () => {
        if (!currentSellPrice.isSuccess || !bestFriendFeePercent.data || !protocolFeePercent.data || !club.data) { return }
        const bestFriendFee = currentSellPrice.data * bestFriendFeePercent.data / BigInt(1e18);
        const protocolFee = currentSellPrice.data * protocolFeePercent.data / BigInt(1e18);
        const tokensOut = currentSellPrice.data - bestFriendFee - protocolFee

        try {
            setErrorMessage('')
            await simulateContract(WAGMI_CONFIG, {
                ...CLUBS,
                functionName: 'sellToken',
                args: [BigInt(club.data?.id ?? 0), tokensOut, (balance.data ?? 0n), '0x27DFcf2F8E8e07Adafcfcf0f81299B909E91Ee32']
            })
            writeContract({
                ...CLUBS,
                functionName: 'sellToken',
                args: [BigInt(club.data?.id ?? 0), tokensOut, (balance.data ?? 0n), '0x27DFcf2F8E8e07Adafcfcf0f81299B909E91Ee32']
            })
        }
        catch (err: any) {
            const errorMessage =
                err instanceof (BaseError) ? err.shortMessage :
                    err instanceof (Error) ? err.message : String(err)
            setErrorMessage(errorMessage)
            console.error(err)
        }
    }

    useEffect(() => {
        if (txId && receipt.isSuccess) {
            resetTx()
            skipClub()
        }
    }, [txId, receipt])

    return (
        <Transition.Root show={true} as={Fragment}>
            <Dialog className="relative z-10" initialFocus={cancelButtonRef} onClose={() => { }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                {!currentClubId
                                    ?
                                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                    All Actions Completed
                                                </Dialog.Title>
                                            </div>
                                        </div>
                                        <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                            <button
                                                onClick={onClose}
                                                className="w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                            >
                                                Close Dialog
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                            <div className="sm:flex sm:items-start">
                                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                        {isBuy ? 'Buy Club' : 'Sell Club'} {club.data?.name} (FT#{currentClubId})
                                                    </Dialog.Title>
                                                    <div className="mt-2">
                                                        <p className="text-sm text-gray-500 font-mono">
                                                            {isBuy ? club.data?.priceBuyGross : club.data?.priceBuyGross} $FRIEND
                                                        </p>
                                                    </div>

                                                    {errorMessage && (
                                                        <div className="rounded-md bg-red-50 p-4">
                                                            <div className="flex">
                                                                <div className="ml-3">
                                                                    <div className="mt-2 text-sm text-red-700">
                                                                        {errorMessage}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                            {txId
                                                ?
                                                receipt.isPending
                                                    ? <a href={`https://basescan.org/tx/${txId}`} target="_blank" rel="noopener noreferrer">Waiting for Transaction <span className='font-mono'>{txId.slice(0, 4)}..${txId.slice(-4)}</span></a>
                                                    : receipt.isError
                                                        ? <span className='items-center'><button onClick={resetTx} className="rounded-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Retry</button> <a href={`https://basescan.org/tx/${txId}`} target="_blank" rel="noopener noreferrer">Transaction Failed</a></span>
                                                        : receipt.isSuccess
                                                            ? <span className='items-center'>Transaction was successful <button onClick={resetTx} className="rounded-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Continue to next Club</button></span>
                                                            : null
                                                :
                                                isBuy

                                                    ?
                                                    <button
                                                        type="button"
                                                        className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                                                        onClick={handleBuy}
                                                    >
                                                        Buy
                                                    </button>
                                                    :

                                                    <button
                                                        type="button"
                                                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                                        onClick={handleSell}
                                                    >
                                                        Sell
                                                    </button>
                                            }
                                            {!txId &&
                                                <button
                                                    type="button"
                                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                    onClick={skipClub}
                                                    ref={cancelButtonRef}
                                                >
                                                    Skip
                                                </button>
                                            }
                                        </div>
                                    </div>
                                }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )

}