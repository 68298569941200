import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useLocation, Link } from 'react-router-dom';
import { useAccount, useReadContracts } from 'wagmi'
import { formatUnits } from 'viem'
import { FRIEND } from '~/config/Contracts';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function LayoutMenu() {
    const location = useLocation();
    const currentPath = location.pathname;
    const account = useAccount()

    const menuItems = [
        { name: 'Clubs', href: '/clubs' },
        { name: 'Trades', href: '/trades' },
        { name: 'Transfers', href: '/transfers' }
    ]
    if (account.address) {
        menuItems.push({ name: 'My Clubs', href: `/clubs/owner/${account.address}` })
    }

    const contracts = useReadContracts({
        contracts: [
            {
                ...FRIEND,
                functionName: 'balanceOf',
                args: [account.address as `0x${string}`],
            },
        ]
    })

    return (
        <div className="flex justify-between w-full border-b border-gray-200 px-4 pt-2 space-x-4">
            <div className="flex-grow">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    {menuItems.map((tab) => (
                        <Link
                            key={tab.name}
                            to={tab.href}
                            className={classNames(
                                currentPath === tab.href
                                    ? 'border-indigo-500 text-indigo-600'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                            )}
                            aria-current={currentPath === tab.href ? 'page' : undefined}
                        >
                            {tab.name}
                        </Link>
                    ))}
                </nav>
            </div>
            <div className='flex items-center border rounded-xl px-4 h-10 shadow-lg shadow-cyan-100 border-white text-sm font-mono font-semibold'>
                {contracts.isSuccess && contracts.data[0].status === 'success' ? Number(Number(formatUnits(contracts.data[0].result, 18)).toFixed(0)).toLocaleString() : 0} $FRIEND
            </div>
            <div><ConnectButton /></div>
        </div>
    )
}