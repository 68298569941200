import { useFrenFren } from "~/hooks/useFrenFren"

export default function Fren({ address, short = false, link = false }: { address?: string, short?: boolean, link?: boolean }) {
    const fren = useFrenFren(address ?? '')

    if (link) {
        return <a href={`https://friend.tech/${address}`} target="_blank" rel="noopener noreferrer"><Fren address={address} short={short} /></a>
    }

    if (!address || !fren?.data?.twitterName) {
        if (address && short) {
            return `${address.slice(0, 4)}..${address.slice(-4)}`

        }
        return address
    }

    return fren.data.twitterName
}