import React from 'react'
import { useParams, Link } from 'react-router-dom';
import { useClub } from '~/hooks/useClub'
import { useFrenFren } from '~/hooks/useFrenFren'
import { useAccount } from 'wagmi'
import { useReadContract, useWriteContract } from 'wagmi'
import { simulateContract } from '@wagmi/core'
import { BaseError } from 'viem';
import { CLUBS } from '~/config/Contracts'

import Trades from './Trades'
import { WAGMI_CONFIG } from '~/App';

export default function ClubsView() {
    const { id } = useParams<{ id: string }>();
    const account = useAccount()
    const { data, isLoading } = useClub(id ?? '', account.address ?? '')
    const frenOwner = useFrenFren(data?.createdWith && data.createdWith[0] ? data.createdWith[0].to?.id : '')

    const [to, setTo] = React.useState('')
    const [amount, setAmount] = React.useState('1')

    const totalSupply = useReadContract({
        ...CLUBS,
        functionName: 'totalSupply',
        args: [BigInt(data?.id ?? 0)]
    })

    const balance = useReadContract({
        ...CLUBS,
        functionName: 'balanceOf',
        args: [(account.address ?? '0x0000000000000000000000000000000000000000'), BigInt(data?.id ?? 0)]
    })

    const currentBuyPrice = useReadContract({
        ...CLUBS,
        functionName: 'getPriceOut',
        args: [(totalSupply.data ?? 0n), BigInt(amount), data?.coefficient]
    })

    const currentSellPrice = useReadContract({
        ...CLUBS,
        functionName: 'getPriceOut',
        args: [(totalSupply.data ?? 0n) - BigInt(amount), BigInt(amount), data?.coefficient]
    })

    const protocolFeePercent = useReadContract({
        ...CLUBS,
        functionName: 'protocolFeePercent'
    })

    const bestFriendFeePercent = useReadContract({
        ...CLUBS,
        functionName: 'bestFriendFeePercent'
    })


    const { writeContract } = useWriteContract()

    const [errorMessage, setErrorMessage] = React.useState('')

    const handleBuy = async () => {
        if (!currentBuyPrice.data || !bestFriendFeePercent.data || !protocolFeePercent.data || !data?.id) { return }
        const bestFriendFee = currentBuyPrice.data * bestFriendFeePercent.data / BigInt(1e18);
        const protocolFee = currentBuyPrice.data * protocolFeePercent.data / BigInt(1e18);
        const tokensIn = currentBuyPrice.data + bestFriendFee + protocolFee

        try {
            setErrorMessage('')
            await simulateContract(WAGMI_CONFIG, {
                ...CLUBS,
                functionName: 'buyToken',
                args: [BigInt(data.id), tokensIn, BigInt(amount), '0x27DFcf2F8E8e07Adafcfcf0f81299B909E91Ee32']
            })

            writeContract({
                ...CLUBS,
                functionName: 'buyToken',
                args: [BigInt(data.id), tokensIn, BigInt(amount), '0x27DFcf2F8E8e07Adafcfcf0f81299B909E91Ee32']
            })
        }
        catch (err: any) {
            const errorMessage =
                err instanceof (BaseError) ? err.shortMessage :
                    err instanceof (Error) ? err.message : String(err)
            setErrorMessage(errorMessage)
            console.error(err)
        }
    }

    const handleSell = async () => {
        if (!currentSellPrice.data || !bestFriendFeePercent.data || !protocolFeePercent.data || !data?.id) { return }
        const bestFriendFee = currentSellPrice.data * bestFriendFeePercent.data / BigInt(1e18);
        const protocolFee = currentSellPrice.data * protocolFeePercent.data / BigInt(1e18);
        const tokensOut = currentSellPrice.data - bestFriendFee - protocolFee

        try {
            setErrorMessage('')
            await simulateContract(WAGMI_CONFIG, {
                ...CLUBS,
                functionName: 'sellToken',
                args: [BigInt(data.id), tokensOut, BigInt(amount), '0x27DFcf2F8E8e07Adafcfcf0f81299B909E91Ee32']
            })
            writeContract({
                ...CLUBS,
                functionName: 'sellToken',
                args: [BigInt(data.id), tokensOut, BigInt(amount), '0x27DFcf2F8E8e07Adafcfcf0f81299B909E91Ee32']
            })
        }
        catch (err: any) {
            const errorMessage =
                err instanceof (BaseError) ? err.shortMessage :
                    err instanceof (Error) ? err.message : String(err)
            setErrorMessage(errorMessage)
            console.error(err)
        }

    }

    const handleTransferTo = async () => {
        if (!to || !data?.id) { return }

        try {
            setErrorMessage('')
            await simulateContract(WAGMI_CONFIG, {
                ...CLUBS,
                functionName: 'transfer',
                args: [to as `0x${string}`, BigInt(data.id), 1n]
            })
            writeContract({
                ...CLUBS,
                functionName: 'transfer',
                args: [to as `0x${string}`, BigInt(data.id), 1n]
            })
        }
        catch (err: any) {
            const errorMessage =
                err instanceof (BaseError) ? err.shortMessage :
                    err instanceof (Error) ? err.message : String(err)
            setErrorMessage(errorMessage)
            console.error(err)
        }
    }

    if (!data || isLoading) {
        return <>Loading ..</>
    }

    const stats = [
        { name: 'Value', value: Number(data.priceBuy).toFixed(0) },
        { name: 'Volume', value: Number(data.volume).toFixed(0) },
        { name: 'Total Keys', value: data.totalSupply.amount },
        { name: 'Members', value: data.holdersCount, changeType: 'positive', change: null },
        { name: 'Historical Unique Members', value: data.holdersCountPast },
        { name: 'Trades', value: data.tradesCount },
        { name: 'Transfers', value: data.transfersCount },
    ]

    return (
        <div className='w-full flex mt-4'>
            <div className='w-48'></div>
            <div className='max-w-lg w-full mx-auto'>
                <div className="sm:px-0 w-full">
                    <div className="flex items-center justify-between">
                        <div>
                            <h3 className="text-3xl font-semibold leading-7 text-gray-900">{data.name}</h3>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                                <a href={`https://friend.tech/clubs/${data.id}/chat`} target="_blank" rel="noopener noreferrer" className='underline decoration-gray-300'>FT#{data.id}</a>
                            </p>
                        </div>
                        {Boolean(data.uri) && <img src={data.uri!} alt={`${data.name} logo`} className="h-24 w-24 rounded-full" />}
                    </div>
                </div>

                <div>
                    <div className="mt-6 border-t border-gray-100">
                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Created By</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    <a className="underline decoration-gray-300" href={`https://www.friend.tech/${data.createdWith && data.createdWith[0] ? data.createdWith[0].to?.id : ''}`} target="_blank" rel="noreferrer noopener">{frenOwner?.data?.twitterName ?? data.createdWith[0].to?.id}</a>

                                    <div className='text-blue-600 space-x-4 mt-2 text-xs font-mono'>
                                        <Link to={`/clubs/owner/${data.createdWith && data.createdWith[0] ? data.createdWith[0].to?.id : ''}`}><button>&raquo; Clubs</button></Link>
                                        <Link to={`https://frenfren.pro/users/${data.createdWith && data.createdWith[0] ? data.createdWith[0].to?.id : ''}`}><button>&raquo; frenfren.pro</button></Link>
                                        <Link to={`https://www.friend.tech/${data.createdWith && data.createdWith[0] ? data.createdWith[0].to?.id : ''}`}><button>&raquo; friend.tech</button></Link>
                                    </div>
                                </dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Created At</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    <a className="underline decoration-gray-300" href={`https://basescan.org/tx/${data.createdWith[0].transaction.id}`} target="_blank" rel="noreferrer noopener">{(new Date(data.createdAt * 1000)).toISOString().slice(0, 19).split('T').join(' ')}</a>
                                </dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Last Transfer At</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {(new Date(data.updatedAt * 1000)).toISOString().slice(0, 19).split('T').join(' ')}
                                    <div className='text-blue-600 space-x-4 mt-2 text-xs font-mono'>
                                        <Link to={`/transfers/${data.id}`}><button>&raquo; Browse Transfers</button></Link>
                                        <Link to={`/trades/${data.id}`}><button>&raquo; Browse Trades</button></Link>
                                    </div>
                                </dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Last Trades</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    <Trades data={data.trades} limit={5} />
                                </dd>
                            </div>
                            {data.myTrades !== undefined && data.myTrades.length > 0 &&
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">My Trades</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <Trades data={data.myTrades} showSum />
                                    </dd>
                                </div>
                            }

                            <div className="px-4 py-6">
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    <div className="w-full flex mt-5">
                                        <span className="text-gray-900 shadow-sm py-1.5 w-16">Key(s):</span>
                                        <input
                                            type="number"
                                            name="amount"
                                            id="amount"
                                            className="text-center font-mono text-xs block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                                            placeholder={amount}
                                            autoComplete='false'
                                            autoCorrect='false'
                                            onChange={(e) => setAmount(e.target.value)}
                                        />
                                    </div>

                                    <div className='w-full'>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                            onClick={handleBuy}
                                            disabled={!account.address}
                                        >
                                            Buy Key
                                        </button>
                                    </div>
                                    <div className='w-full'>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                            onClick={handleSell}
                                            disabled={!account.address}
                                        >
                                            Sell Key
                                        </button>
                                    </div>


                                    {balance.isSuccess && balance.data > 0n && (
                                        <>
                                            <div className="w-full flex mt-8">
                                                <span className="text-gray-900 shadow-sm py-1.5 w-16">Gift To:</span>
                                                <input
                                                    type="text"
                                                    name="to"
                                                    id="to"
                                                    className="text-center font-mono text-xs block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                                                    placeholder='0x..'
                                                    autoComplete='false'
                                                    autoCorrect='false'
                                                    onChange={(e) => setTo(e.target.value)}
                                                />
                                            </div>
                                            <div className='w-full'>
                                                <button
                                                    type="button"
                                                    className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                                    onClick={handleTransferTo}
                                                >
                                                    Gift Key
                                                </button>
                                            </div>
                                        </>
                                    )}

                                    {errorMessage && (
                                        <div className="rounded-md bg-red-50 p-4">
                                            <div className="flex">
                                                <div className="ml-3">
                                                    <div className="mt-2 text-sm text-red-700">
                                                        {errorMessage}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </dd>


                            </div>
                        </dl>
                    </div>
                </div>
            </div>
            <div className='w-48'>
                <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-1 text-right">
                    {stats.map((stat) => (
                        <div
                            key={stat.name}
                            className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8"
                        >
                            <dt className="text-sm font-medium text-gray-500 w-full">{stat.name}</dt>
                            <dd
                                className={classNames(
                                    stat.changeType === 'negative' ? 'text-rose-600' : 'text-gray-700',
                                    'text-xs font-medium'
                                )}
                            >
                                {stat.change}
                            </dd>
                            <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                                {Number(stat.value).toLocaleString()}
                            </dd>
                        </div>
                    ))}
                </dl>
            </div>

        </div>
    )
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}
