import { useQuery } from '@tanstack/react-query'

export type FrenFren = {
    address: string;
    twitterName: string;
    twitterUsername: string;
    twitterPfpUrl: string;
    frens: {
        count: number;
        score: number;
    };
    points: {
        totalPoints: number;
        tier: string;
    };
    portfolio: {
        count: number;
        value: number;
        liquidation: number;
        paid: number;
        pnl: number;
        performer: {
            best: {
                address: string;
                twitterName: string;
                twitterPfpUrl: string;
                paid: number;
                sold: number;
                currentValue: number;
                keys: number;
            };
            worst: {
                address: string;
                twitterName: string;
                twitterPfpUrl: string;
                paid: number;
                sold: number;
                currentValue: number;
                keys: number;
            };
        };
    };
    key: {
        value: number;
        buy: number;
        sell: number;
        mcap: number;
        fees: number;
        holders: {
            count: number;
            supply: number;
        };
    };
}
export function useFrenFren(address: string) {
    return useQuery({
        queryKey: ['frenfren', address],
        queryFn: async () => {
            return await fetch(`https://frenfren.pro/api/users/${address}`).then(res => res.json()) as FrenFren
        },
        placeholderData: (previousData) => previousData,
        retry: false
    })
}