import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useTradesSearch } from '~/hooks/useTradesSearch'
import {
    type Column,
    type ColumnFiltersState,
    type RowData,
    getCoreRowModel,
    SortingState,
    createColumnHelper,
    flexRender,
    useReactTable
} from '@tanstack/react-table'
import type { TokenTrade } from "~/../.graphclient"
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'


const columnHelper = createColumnHelper<TokenTrade>()

export default function TradesList() {
    const { id } = useParams<{ id: string }>();
    const [sorting, setSorting] = React.useState<SortingState>([{
        id: 'timestamp',
        desc: true
    }])

    const [pagination, setPagination] = React.useState({
        pageSize: 10,
        pageIndex: 0,
    });
    const [filters, setFilters] = React.useState<ColumnFiltersState>([])
    const { status, data, error, isFetching } = useTradesSearch({
        first: pagination.pageSize,
        skip: pagination.pageIndex * pagination.pageSize,
        orderBy: sorting && sorting[0] ? sorting[0].id : undefined,
        orderDirection: sorting && sorting[0] && sorting[0].desc ? 'desc' : 'asc',
        filters: filters.reduce((acc, { value: { value, variable } }) => ({ ...acc, [variable]: value }),
            !id ?
                {}
                : { token: id }
        )
    })


    const columns = React.useMemo(() => {
        const columns = []
        columns.push(
            columnHelper.accessor('timestamp', {
                header: 'Time',
                id: 'timestamp',
                enableSorting: true,
                sortDescFirst: true,
                enableColumnFilter: false,
                cell: (props) => <div className='font-mono text-xs'><a className="underline decoration-gray-300" href={`https://basescan.org/tx/${props.row.original.transaction.id}`} target="_blank" rel="noreferrer noopener">{(new Date(props.getValue() * 1000)).toISOString().slice(0, 19).split('T').join(' ')}</a></div>
            }),
            columnHelper.accessor('traderHolder.account.id', {
                header: 'Traded By',
                id: 'traderHolder__id',
                enableSorting: true,
                enableColumnFilter: true,
                meta: {
                    filterVariant: 'text',
                    variable: 'trader_contains_nocase'
                },
                cell: (props) => <div className='text-center font-mono text-xs'><a className="underline decoration-gray-300" href={`https://www.friend.tech/${props.row.original.traderHolder?.account?.id}`} target="_blank" rel="noreferrer noopener">{props.getValue() ? `${props.getValue().slice(0, 4)}..${props.getValue().slice(-4)}` : '…'}</a></div>,
            })
        )

        if (!id) {
            columns.push(
                columnHelper.accessor('token.identifier', {
                    header: 'FT#',
                    enableSorting: true,
                    enableColumnFilter: true,

                    meta: {
                        filterVariant: 'text',
                        variable: 'token'
                    },
                    cell: (props) => <div className='text-right font-mono text-xs w-12'><Link className="underline decoration-gray-300" to={`/clubs/${props.row.original.token.identifier}`}>{props.getValue()}</Link></div>
                })
            )
        }

        columns.push(
            columnHelper.accessor('token.name', {
                header: 'Name',
                id: 'token__name',
                enableSorting: true,
                enableColumnFilter: true,
                meta: {
                    filterVariant: 'text',
                    variable: 'token_contains_nocase'
                },
                cell: (props) => <div className='text-left font-mono text-xs w-32 truncate text-ellipsis'><Link className="underline decoration-gray-300" to={`/clubs/${props.row.original.token.id}`}>{props.getValue()}</Link></div>
            }),
            columnHelper.accessor('isBuy', {
                header: 'Action',
                id: 'isBuy',
                enableSorting: false,
                cell: (props) => <div className={`text-left font-mono text-xs w-32 truncate text-ellipsis ${props.getValue() ? 'text-green-700' : 'text-red-700'}`}>{props.getValue() ? 'buy' : 'sell'}</div>
            }),

            columnHelper.accessor('friendValue', {
                header: 'Price',
                id: 'friendValue',
                cell: (props) => <div className={`text-left font-mono text-xs w-32 truncate text-ellipsis ${props.row.original.isBuy ? 'text-green-700' : 'text-red-700'}`}>{props.getValue()}</div>
            }),
        )

        return columns
    }, [id])

    const table = useReactTable({
        data: data ?? [],
        columns,
        onPaginationChange: setPagination,
        manualPagination: true,
        manualSorting: true,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        onColumnFiltersChange: setFilters,
        state: {
            sorting,
        },
    })

    return (
        <div className='w-full'>
            {status === 'pending' ? (
                'Loading...'
            ) : status === 'error' ? (
                <span>Error: {error.message}</span>
            ) : (
                <div className='space-y-3'>
                    <div>
                        <table className="w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th
                                                key={header.id}
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer whitespace-nowrap"
                                            >
                                                <div
                                                    className='h-4'
                                                    {...(header.column.getCanSort()
                                                        ? { onClick: header.column.getToggleSortingHandler() }
                                                        : {})}
                                                >
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}

                                                    {header.column.getIsSorted() === "asc" ? (
                                                        <span> ▲</span>
                                                    ) : header.column.getIsSorted() === "desc" ? (
                                                        <span> ▼</span>
                                                    ) : null}

                                                </div>
                                                <div className='h-4'>
                                                    {header.column.getCanFilter() ?
                                                        (
                                                            <div>
                                                                <Filter column={header.column} />
                                                            </div>
                                                        )
                                                        : null}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody
                                className="bg-white divide-y divide-gray-200"
                            >
                                {table.getRowModel().rows.map(row => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map(cell => (
                                            <td
                                                key={cell.id}
                                                className="px-6 py-4"
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
                            <div className="-mt-px flex w-0 flex-1">
                                <button
                                    onClick={table.previousPage}
                                    className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                >
                                    <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    Previous
                                </button>
                            </div>
                            <div className="hidden md:-mt-px md:flex">
                                <span
                                    className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                >
                                    Page {pagination.pageIndex + 1}
                                </span>

                                <span
                                    className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                >
                                    –
                                </span>

                                <div className='inline-flex px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 items-center'>
                                    <span>Entries per Page:</span>
                                    <select
                                        value={pagination.pageSize}
                                        onChange={(e) => table.setPageSize(parseInt(e.target.value, 10))}
                                    >
                                        {[10, 25, 50, 100].map((size) => (
                                            <option key={size} value={size}>
                                                {size}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                            </div>
                            <div className="-mt-px flex w-0 flex-1 justify-end">
                                <button
                                    onClick={table.nextPage}
                                    className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                >
                                    Next
                                    <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                </button>
                            </div>
                        </nav>
                        <div>{isFetching ? 'Background Updating...' : ' '}</div>
                    </div>
                </div>
            )}
        </div>
    )
}


declare module '@tanstack/react-table' {
    //allows us to define custom properties for our columns
    interface ColumnMeta<TData extends RowData, TValue> {
        filterVariant?: 'text' | 'range' | 'select'
        variable?: string
    }
}

function Filter({ column }: { column: Column<TokenTrade, unknown> }) {
    const { filterVariant, variable } = column.columnDef.meta ?? {}
    const columnFilterValue = column.getFilterValue() as { variable?: string, value?: string | number } | undefined

    if (filterVariant === 'text') {
        return (
            <div>
                <DebouncedInput
                    type="text"
                    value={(columnFilterValue?.value ?? '') as string}
                    onChange={value => column.setFilterValue({ variable, value })}
                    placeholder={`search..`}
                    className="w-full border shadow-inner rounded p-1 text-xs"
                    list={column.id + 'list'}
                />
            </div>
        )
    }
}

function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = React.useState(initialValue)

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <input {...props} value={value} onChange={e => setValue(e.target.value)} />
    )
}
