import React, { type HTMLProps } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useClubsSearch } from '~/hooks/useClubsSearch'
import {
    type Column,
    type ColumnFiltersState,
    type ColumnDef,
    type RowData,
    getCoreRowModel,
    SortingState,
    createColumnHelper,
    flexRender,
    useReactTable
} from '@tanstack/react-table'
import type { ClubToken, TokenTransfer } from "~/../.graphclient"
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'
import { useAccount } from 'wagmi'
import Batch from '../Batch'

const columnHelper = createColumnHelper<ClubToken & { createdWith: [TokenTransfer] }>()
const columns = [
    columnHelper.accessor('id', {
        footer: info => info.column.id,
        header: 'FT#',
        enableSorting: true,
        enableColumnFilter: true,
        meta: {
            filterVariant: 'text',
            variable: 'identifier'
        },
        cell: (props) => <div className='text-right font-mono text-xs w-12'><Link className="underline decoration-gray-300" to={`/clubs/${props.row.original.id}`}>{props.getValue()}</Link></div>
    }),
    columnHelper.accessor('name', {
        header: 'Name',
        id: 'name',
        enableSorting: true,
        enableColumnFilter: true,
        meta: {
            filterVariant: 'text',
            variable: 'name_contains_nocase'
        },
        cell: (props) => <div className='text-left font-mono text-xs w-32 truncate text-ellipsis'><Link className="underline decoration-gray-300" to={`/clubs/${props.row.original.id}`}>{props.getValue()}</Link></div>
    }),
    columnHelper.accessor('createdAt', {
        header: 'Created At',
        id: 'createdAt',
        enableSorting: true,
        sortDescFirst: true,
        enableColumnFilter: false,
        cell: (props) => <div className='font-mono text-xs'><a className="underline decoration-gray-300" href={`https://basescan.org/tx/${props.row.original.createdWith[0].transaction.id}`} target="_blank" rel="noreferrer noopener">{(new Date(props.getValue() * 1000)).toISOString().slice(0, 19).split('T').join(' ')}</a></div>
    }),
    columnHelper.accessor('createdBy.id', {
        header: 'Created By',
        id: 'createdBy__id',
        enableSorting: true,
        enableColumnFilter: true,
        meta: {
            filterVariant: 'text',
            variable: 'createdBy_contains_nocase'
        },
        cell: (props) => <div className='text-center font-mono text-xs'><a className="underline decoration-gray-300" href={`https://www.friend.tech/${props.row.original.createdBy.id}`} target="_blank" rel="noreferrer noopener">{props.getValue() ? `${props.getValue().slice(0, 4)}..${props.getValue().slice(-4)}` : '…'}</a></div>,
    }),
    columnHelper.accessor('updatedAt', {
        header: 'Last Change',
        id: 'updatedAt',
        enableSorting: true,
        sortDescFirst: true,
        enableColumnFilter: false,
        cell: (props) => <div className='text-center font-mono text-xs'>{(new Date(props.getValue() * 1000)).toISOString().slice(0, 19).split('T').join(' ')}</div>
    }),
    columnHelper.accessor(row => row.holdersCount, {
        header: 'Members',
        id: 'holdersCount',
        enableSorting: true,
        sortDescFirst: true,
        enableColumnFilter: false,
        cell: (row) => <div className='text-right font-mono text-xs'>{row.getValue()}</div>
    }),
    columnHelper.accessor(row => row.tradesCount, {
        header: 'Trades',
        id: 'tradesCount',
        enableSorting: true,
        sortDescFirst: true,
        enableColumnFilter: false,
        cell: (row) => <div className='text-right font-mono text-xs'>{row.getValue()}</div>
    }),
    columnHelper.accessor(row => row.transfersCount, {
        header: 'Transfers',
        id: 'transfersCount',
        enableSorting: true,
        sortDescFirst: true,
        enableColumnFilter: false,
        cell: (row) => <div className='text-right font-mono text-xs'>{row.getValue()}</div>
    }),
    columnHelper.accessor(row => row.volume, {
        header: 'Volume',
        id: 'volume',
        enableSorting: true,
        sortDescFirst: true,
        enableColumnFilter: false,
        cell: (row) => <div className='text-right font-mono text-xs'>{Number(row.getValue()).toFixed(0)}</div>
    }),
    columnHelper.accessor(row => row.priceBuyGross, {
        header: 'Buy Price',
        id: 'priceBuyGross',
        enableSorting: true,
        sortDescFirst: true,
        enableColumnFilter: false,
        cell: (row) => <div className='text-right font-mono text-xs'>{Number(row.getValue()).toFixed(0)}</div>
    }),
    columnHelper.accessor(row => row.priceSellGross, {
        header: 'Sell Price',
        id: 'priceSellGross',
        enableSorting: true,
        sortDescFirst: true,
        enableColumnFilter: false,
        cell: (row) => <div className='text-right font-mono text-xs'>{Number(row.getValue()).toFixed(0)}</div>
    }),
]

export default function ClubsList() {
    const { ownerAddress } = useParams<{ ownerAddress: string }>();
    const [sorting, setSorting] = React.useState<SortingState>([{
        id: 'holdersCount',
        desc: true
    }])
    const [pagination, setPagination] = React.useState({
        pageSize: 10,
        pageIndex: 0,
    });
    const [filters, setFilters] = React.useState<ColumnFiltersState>([])

    const { status, data, error, isFetching } = useClubsSearch({
        first: pagination.pageSize,
        skip: pagination.pageIndex * pagination.pageSize,
        orderBy: sorting && sorting[0] ? sorting[0].id : undefined,
        orderDirection: sorting && sorting[0] && sorting[0].desc ? 'desc' : 'asc',
        filters: {
            ...filters.reduce((acc, { value: { value, variable } }) => ({ ...acc, [variable]: value }), {}),
            ownerAddress: ownerAddress ?? null
        }
    })

    const [rowSelection, setRowSelection] = React.useState({})
    const account = useAccount()
    const [displayBatch, setDisplayBatch] = React.useState(false)
    const showBatch = () => setDisplayBatch(true)
    const hideBatch = () => setDisplayBatch(false)
    const [batchAction, setBatchAction] = React.useState(true)

    const selectionColumn = React.useMemo<ColumnDef<ClubToken & { createdWith: [TokenTransfer] }>>(
        () => {
            return {
                id: 'select',
                header: ({ table }) => (
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                ),
                cell: ({ row }) => (
                    <div className="px-1">
                        <IndeterminateCheckbox
                            {...{
                                checked: row.getIsSelected(),
                                disabled: !row.getCanSelect(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: row.getToggleSelectedHandler(),
                            }}
                        />
                    </div>
                ),
            }
        }
        , [])

    const table = useReactTable({
        getRowId: row => row.id,
        data: data ?? [],
        columns: [selectionColumn, ...columns],
        onPaginationChange: setPagination,
        manualPagination: true,
        manualSorting: true,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        onColumnFiltersChange: setFilters,
        onRowSelectionChange: setRowSelection,
        enableRowSelection: true,
        state: {
            sorting,
            rowSelection,
        },
    })

    return (
        <div className='w-full'>
            {displayBatch && <Batch ids={Object.keys(rowSelection)} isBuy={batchAction} onClose={hideBatch} />}
            {status === 'pending' ? (
                'Loading...'
            ) : status === 'error' ? (
                <span>Error: {error.message}</span>
            ) : (
                <div className='space-y-3'>
                    <div>
                        <table className="w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th
                                                key={header.id}
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer whitespace-nowrap"
                                            >
                                                <div
                                                    className='h-4'
                                                    {...(header.column.getCanSort()
                                                        ? { onClick: header.column.getToggleSortingHandler() }
                                                        : {})}
                                                >
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}

                                                    {header.column.getIsSorted() === "asc" ? (
                                                        <span> ▲</span>
                                                    ) : header.column.getIsSorted() === "desc" ? (
                                                        <span> ▼</span>
                                                    ) : null}

                                                </div>
                                                <div className='h-4'>
                                                    {header.column.getCanFilter() ?
                                                        (
                                                            <div>
                                                                <Filter column={header.column} />
                                                            </div>
                                                        )
                                                        : null}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody
                                className="bg-white divide-y divide-gray-200"
                            >
                                {table.getRowModel().rows.map(row => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map(cell => (
                                            <td
                                                key={cell.id}
                                                className="px-5 py-4"
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer whitespace-nowrap"
                                    >
                                        <IndeterminateCheckbox
                                            {...{
                                                checked: table.getIsAllPageRowsSelected(),
                                                indeterminate: table.getIsSomePageRowsSelected(),
                                                onChange: table.getToggleAllPageRowsSelectedHandler(),
                                            }}
                                        />
                                    </td>
                                    <td
                                        colSpan={columns.length}
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap space-x-4"
                                    >
                                        <span>{Object.keys(rowSelection).length} Clubs Selected</span>
                                        <button
                                            type="button"
                                            className={`inline-flex text-xs items-center justify-center rounded-md bg-green-600 px-3 py-1 font-thin text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 ${!account.address || !Object.keys(rowSelection).length ? 'opacity-50' : ''}`}
                                            onClick={() => { setBatchAction(true), showBatch() }}
                                        >
                                            Buy Selected Clubs
                                        </button>
                                        <button
                                            type="button"
                                            className={`inline-flex text-xs items-center justify-center rounded-md bg-red-600 px-3 py-1 font-thin text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 ${!account.address || !Object.keys(rowSelection).length ? 'opacity-50' : ''}`}
                                            onClick={() => { setBatchAction(false), showBatch() }}
                                        >
                                            Sell Selected Clubs
                                        </button>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                        <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
                            <div className="-mt-px flex w-0 flex-1">
                                <button
                                    onClick={table.previousPage}
                                    className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                >
                                    <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    Previous
                                </button>
                            </div>
                            <div className="hidden md:-mt-px md:flex">
                                <span
                                    className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                >
                                    Page {pagination.pageIndex + 1}
                                </span>

                                <span
                                    className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                >
                                    –
                                </span>

                                <div className='inline-flex px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 items-center'>
                                    <span>Entries per Page:</span>
                                    <select
                                        value={pagination.pageSize}
                                        onChange={(e) => table.setPageSize(parseInt(e.target.value, 10))}
                                    >
                                        {[10, 25, 50, 100].map((size) => (
                                            <option key={size} value={size}>
                                                {size}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                            </div>
                            <div className="-mt-px flex w-0 flex-1 justify-end">
                                <button
                                    onClick={table.nextPage}
                                    className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                >
                                    Next
                                    <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                </button>
                            </div>
                        </nav>
                        <div>{isFetching ? 'Background Updating...' : ' '}</div>
                    </div>
                </div>
            )}
        </div>
    )
}


declare module '@tanstack/react-table' {
    //allows us to define custom properties for our columns
    interface ColumnMeta<TData extends RowData, TValue> {
        filterVariant?: 'text' | 'range' | 'select'
        variable?: string
    }
}

function Filter({ column }: { column: Column<ClubToken & { createdWith: [TokenTransfer] }, unknown> }) {
    const { filterVariant, variable } = column.columnDef.meta ?? {}
    const columnFilterValue = column.getFilterValue() as { variable?: string, value?: string | number } | undefined

    if (filterVariant === 'text') {
        return (
            <div>
                <DebouncedInput
                    type="text"
                    value={(columnFilterValue?.value ?? '') as string}
                    onChange={value => column.setFilterValue({ variable, value })}
                    placeholder={`search..`}
                    className="w-full border shadow-inner rounded p-1 text-xs"
                    list={column.id + 'list'}
                />
            </div>
        )
    }
}

function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = React.useState(initialValue)

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <input {...props} value={value} onChange={e => setValue(e.target.value)} />
    )
}


function IndeterminateCheckbox({
    indeterminate,
    className = '',
    ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = React.useRef<HTMLInputElement>(null!)

    React.useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate
        }
    }, [ref, indeterminate])

    return (
        <input
            type="checkbox"
            ref={ref}
            className={className + ' cursor-pointer'}
            {...rest}
        />
    )
}