import { useQuery } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import { GQL_ENDPOINT } from "~/config"

import type { ClubToken, TokenTrade, TokenTransfer } from "~/../.graphclient"
interface ClubsQuery {
  clubToken: (ClubToken & { createdWith: [TokenTransfer], myTrades?: [TokenTrade] })
}

const ClubQueryDocument = gql`
query Club($id: ID = "") {
    clubToken(id: $id) {
      createdAt
      coefficient
      holdersCount
      holdersCountPast
      id
      identifier
      name
      priceBuy
      priceBuyExact
      priceBuyGross
      priceBuyGrossExact
      priceSell
      priceSellExact
      priceSellGross
      priceSellGrossExact
      tradesCount
      transfersCount
      updatedAt
      volume
      volumeBuy
      volumeSell
      uri
      totalSupply {
        amount
      }
      trades(first: 10, orderBy: timestamp, orderDirection: desc) {
        amount
        friendValue
        friendValueExact
        id
        isBuy
        timestamp
        trader {
          id
        }
      }
      myTrades: trades(orderBy: timestamp, orderDirection: desc, where: { trader: "0x15C588372592ae73e5F86ccF430FA63B9B0852d6"}) {
        amount
        friendValue
        friendValueExact
        id
        isBuy
        timestamp
        trader {
          id
        }
      }
      createdWith: transfers(first: 1, orderBy: timestamp, orderDirection: asc) {
        transaction {
          id
          timestamp
          blockNumber
        }
        to {
          id
        }
      }
    }
  }
`

const ClubQueryDocumentPerspective = gql`
query Club($id: ID = "", $owner: String = "") {
    clubToken(id: $id) {
      createdAt
      coefficient
      holdersCount
      holdersCountPast
      id
      identifier
      name
      priceBuy
      priceBuyExact
      priceBuyGross
      priceBuyGrossExact
      priceSell
      priceSellExact
      priceSellGross
      priceSellGrossExact
      tradesCount
      transfersCount
      updatedAt
      volume
      volumeBuy
      volumeSell
      uri
      totalSupply {
        amount
      }
      trades(first: 10, orderBy: timestamp, orderDirection: desc) {
        amount
        friendValue
        friendValueExact
        id
        isBuy
        timestamp
        trader {
          id
        }
      }
      myTrades: trades(first: 10, orderBy: timestamp, orderDirection: desc, where: { trader: $owner}) {
        amount
        friendValue
        friendValueExact
        id
        isBuy
        timestamp
        trader {
          id
        }
      }
      createdWith: transfers(first: 1, orderBy: timestamp, orderDirection: asc) {
        transaction {
          id
          timestamp
          blockNumber
        }
        to {
          id
        }
      }
    }
  }
`
export function useClub(id: string, owner: string) {

  return useQuery({
    queryKey: ['club', id, owner],
    queryFn: async () => {
      const { clubToken } = await request<ClubsQuery & { createdWith: [TokenTransfer] }>(
        GQL_ENDPOINT,
        owner ? ClubQueryDocumentPerspective : ClubQueryDocument,
        { id, owner }
      )
      return clubToken
    },
    placeholderData: (previousData) => previousData,
    retry: false
  })
}