import FriendsAbi from './abis/Friend';
import ClubsAbi from './abis/Clubs';

const FRIEND = {
    address: '0x0bD4887f7D41B35CD75DFF9FfeE2856106f86670',
    abi: FriendsAbi,
} as const

const CLUBS = {
    address: '0x201e95f275f39a5890c976dc8a3e1b4af114e635',
    abi: ClubsAbi
} as const

export { FRIEND, CLUBS }